import React from 'react';

const Service = () => {
	return (
		<div style={{ width: '100%' }}>
			<div className="policy container pettok_home_container">
				<div className="content">
					<div className="content_inner">
						<div className="section detail_list_section">
							<div className="section_info">
								<h2 className="section_title">서비스 이용약관</h2>
							</div>
							<div style={{ width: '100%' }}>
								<dl className="policy_content">
									<dt id="index1">제 1조 (목적)</dt>

									<ul style={{ paddingLeft:'10px' }}>
										<li style={{ listStyle:'none' }}>
											이 약관은 (주)펫닥이 운영하는 펫닥(
											<a href="https://petdoc.co.kr" target="_blank">
												https://petdoc.co.kr
											</a>
											) 과 V+care, Four Paws (
											<a href="https://fourpaws.co.kr" target="_blank">
												https://fourpaws.co.kr
											</a>
											) 등의 패밀리 사이트 **(이하 "펫닥" 또는 "회사"라 한다)**에서 제공하는 온·오프라인 서비스(이하 "서비스"라 한다)의 이용조건 및 절차에 관한 기본사항을 정하는데 목적이 있습니다.
										</li>
									</ul>
									<dt id="index2">제 2조 (정의)</dt>

									<ol className="step1">
										<li>
											이 약관에서 사용하는 용어의 의미는 다음 각 호와 같습니다. 아래 각 호에서 정의되지 않은 이 약관상의 용어의 의미는 "펫닥" 이용약관 및 일반적인 거래관행에 의합니다.
											<ol className="step2">
												<li style={{ listStyle:'none' }}>
													1) "펫닥"은 반려동물 정보 기준으로 상담, 재화, 제반서비스를 운영하는 사업자를 말하며,
													아울러 재화 또는 용역을 사용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여
													재화 또는 용역을 거래할 수 있도록 설정한 가상의 영업장 및 사이버몰 운영하는 사업자의 의미로도 사용합니다.
												</li>
												<li style={{ listStyle:'none' }}>2) "고객"이란 "펫닥"을 이용하는 이용자를 말합니다.</li>
												<li style={{ listStyle:'none' }}>
													3) "회원"이란 회사에 개인정보를 제공하고 회원등록을 한 자로서, "펫닥"의 정보를 지속적으로 제공
													받으며, "펫닥"의 "서비스"를 계속적으로 이용할 수 있는 자를 말합니다.
												</li>
												<li style={{ listStyle:'none' }}>
													4) "비회원"이라 함은 "회원"으로 가입하지 않고 "펫닥"이 제공하는 "서비스"를 이용하는 자를 말합니다.
												</li>
												<li style={{ listStyle:'none' }}>
													5) "반려동물서비스제공자"라 함은 수의사 및 반려동물관련 서비스업체 등 "펫닥" 내에서 "서비스"제공을 하는 자를 말합니다.
												</li>

											</ol>
										</li>
										<li>이 약관은 관계 법령 등에서 정하는 바에 따릅니다.</li>
									</ol>

									<dt id="index3">제 3조 (회원 계약 및 서비스 이용 계약의 체결)</dt>

									<ol className="step1">
										<li>
											가입신청인이 본 약관을 읽고 "동의" 버튼을 누르거나 "확인" 등에 체크하는 방법을 취한 경우 본 약관에 동의한 것으로 간주합니다.
										</li>
										<li>
											비회원 이용자가 회사가 정한 양식에 따라 필요한 정보를 입력한 뒤 본 약관을 읽고
											"동의" 버튼을 누르거나 "확인" 등에 체크하는 방법을 취하는 경우 본 약관에 동의하는 것으로 간주 합니다.
										</li>
										<li>
											펫닥은 제 1항의 가입신청과 관련하여 가입신청인에게 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
										</li>
										<li>
											가입신청인은 펫닥의 요청에 따라 본인의 개인정보 중 필수 항목 (성명, 생년월일, 성별, 주소, 아이디, 비밀번호, 닉네임, 전화번호, 전자우편 주소)를 제공하여야합니다.
										</li>
										<li>
											가입신청인은 이용하려는 펫닥의 서비스에 따라 반려동물 정보 중 필수 항목(품종,나이, 성별, 중성화 여부, 접종 여부 등)을 제공하여야 합니다.
										</li>
										<li>
											펫닥은 가입신청인의 서비스 이용 신청에 대해 승낙함을 원칙으로 합니다. 다만 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 회원계약을 해지할 수 있습니다.
											<ol className="step2">
												<li style={{ listStyle:'none' }}>1) 실명이 아니거나 타인의 명의를 사용한 경우</li>
												<li style={{ listStyle:'none' }}>
													2) 가입신청인이 회원가입일 이전에 이 약관에 의하여 회원자격을 상실한 적이 있는 경우, 단 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 함.
												</li>
												<li style={{ listStyle:'none' }}>3) 허위 정보를 기재하거나 회사가 제시한 필수 항목을 기재하지 않은 경우</li>
												<li style={{ listStyle:'none' }}>4) 이용자가 부정한 용도로 서비스를 이용하는 경우</li>
												<li style={{ listStyle:'none' }}>
													5) 관계법령에 위배되거나 선량한 풍속 기타 사회질서를 저해할 수 있는 목적으로 신청한 경우
												</li>
												<li style={{ listStyle:'none' }}>
													6) 기타 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 제반 사항을 위반하여 신청한 경우
												</li>
												<li style={{ listStyle:'none' }}>
													7) 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
												</li>
												<li style={{ listStyle:'none' }}>
													8) 제5항 또는 제 6항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 원칙적으로 가입신청인에게 그 사실을 알리도록 합니다.
												</li>
											</ol>
										</li>
										<li>또는 “회사” 는 다음 각 호에 해당하는 예약 신청에 대하여 변경하거나 취소 할 수 있습니다.</li>
											<ol className="step2">
												<li style={{ listStyle:'none' }}>1) 이용자가 희망하는 예약날짜에 장례식장의 사정으로 운영 하지 않을 경우</li>
												<li style={{ listStyle:'none' }}>2) 이용자가 희망하는 예약날짜에 장례식장의 사정으로 장례를 진행할 수 없는 경우</li>
												<li style={{ listStyle:'none' }}>3) 이용자의 정보가 실명이 아니거나 타인의 명의를 이용한 경우</li>
												<li style={{ listStyle:'none' }}>4) 접수 내용에 허위의 정보를 기재하거나, 기재누락, 오기가 있는 경우</li>
												<li style={{ listStyle:'none' }}>5) 부정한 용도 또는 영리를 추구할 목적으로 본 서비스를 이용하고자 하는 경우</li>
												<li style={{ listStyle:'none' }}>6) 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우</li>
											</ol>
										<li>
											제5항 또는 제 6항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 원칙적으로 가입신청인에게 그 사실을 알리도록 합니다.
										</li>
										<li>
											회원계약의 체결 시기는 가입 신청인이 제 1항과 같이 동의한 후, 회사가 정한 온라인 회원가입 신 청서에 회원 ID를 포함한 필수사항을 입력하고, "가입하기" 내지 "확인" 버튼을 누른 시점으로 합니다.
										</li>
									</ol>
									<dt id="index4">제 4조 (회원 계약의 해지)</dt>
									<ol className="step1">
										<li>
											회원은 언제든지 회사에게 회원계약 해지를 신청할 수 있고 회사는 관련 법령 등이 정하는 바에 따라 3 영업일 이내에 이를 처리합니다.
										</li>
										<li>
											회사는 회원에게 회원계약 위반 등 다음 각 호의 사유가 발생한 경우 2주 이상의 기간을 정하여 서면(전자문서 또는 전자우편을 포함)으로 회원계약 위반의 시정을 최고할 수 있고,
											회원이 그 기간 내에 시정하지 아니할 경우 회사는 회원에게 해지의 의사를 표시함으로써 회원계약을 해지할 수 있습니다.
											다만, 회원에게 회원계약 상의 권리 행사 또는 의무의 이행을 계속할 수 없는 사유가 발생한 경우 회사는 최고 없이 회원계약을 해지할 수 있습니다.
											<ol className="step2">
												<li style={{ listStyle:'none' }}>1) 회원이 서비스와 관련하여 회사 또는 제 3자에게 직·간접적으로 재산적·비재산적 손해를 가한 경우</li>
												<li style={{ listStyle:'none' }}>2) 회원이 회사 또는 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하여 회사의 영업을 침해한 경우</li>
												<li style={{ listStyle:'none' }}>3) 회원이 이 약관에 위배되는 행위를 하거나 약관에서 정한 해지사유가 발생한 경우</li>
											</ol>
										</li>
										<li>
											회사 또는 회원이 회원계약을 해지하는 경우, 관련법 및 개인정보 처리 방침에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 해지업무 처리 완료 즉시 데이터는 소멸됩니다.
										</li>
										<li>
											회사 또는 회원이 회원계약을 해지하는 경우, 회원의 적립금은 소멸되며 회원은 적립금 소멸로 인하여 발생하는 경제적 이익의 상실에 관하여 회사에게 손해배상 등 법적 청구를 할 수 없습니다.
										</li>
										<li>회원계약의 해지 시기는 회사가 회원에게 회원계약 해지 사실(회원탈퇴)를 표시한 때로 합니다.</li>
									</ol>

									<dt id="index5">제 5조 (서비스의 내용)</dt>
									<ol className="step1">
										<li>"회사"는 "고객"이 직접 입력/등록한 회원의 정보와 반려동물의 정보를 이용합니다.</li>
										<li>"고객"의 정보와 반려동물의 정보는 해당 스마트폰 등에서 고객이 직접 입력하여 제공합니다.</li>
										<li>"회사"는 "고객"이 직접 입력한 반려동물의 정보를 "반려동물서비스제공자"에게 제공할 수 있습니다.</li>
									</ol>

									<dt id="index6">제 6조 (서비스의 제공 및 변경)</dt>

									<ol className="step1">
										<li>
											펫닥은 다음과 같은 업무를 제공합니다.
											<ol className="step2">
												<li style={{ listStyle:'none' }}>1) 수의사 상담 서비스</li>
												<li style={{ listStyle:'none' }}>2) 반려동물 병원 진료 서비스</li>
												<li style={{ listStyle:'none' }}>3) 반려동물 미용 및 관리 서비스</li>
												<li style={{ listStyle:'none' }}>4) 반려동물 행동교정 서비스</li>
												<li style={{ listStyle:'none' }}>5) 반려동물 입양 중개 서비스</li>
												<li style={{ listStyle:'none' }}>6) 반려동물 장례 서비스</li>
												<li style={{ listStyle:'none' }}>7) 반려동물 커뮤니티 서비스</li>
												<li style={{ listStyle:'none' }}>8) 기타 회사가 추가 개발하거나 다른 회사와 제휴 계약 등을 통해 제공하는 서비스</li>
											</ol>
										</li>
										<li>
											회사는 이용 감소로 인한 원활한 서비스 제공의 곤란 및 수익성 악화, 서비스 제공과 관련한 회사 정책의 변경 등 기타 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는
											일부 서비스를 변경 또는 중단할 수 있습니다.
										</li>
										<li>
											회사는 서비스의 변경 또는 서비스 중단이 예정된 경우 예정일로부터 30일 이상의 기간 전 회사 홈페이지(서비스 초기화면)에 변경 또는 중단될 서비스의 종류, 변경 또는 중단 내용과 사유, 변경 또는 중단 일시 등을 공지합니다.
										</li>
										<li>
											회사는 이용자에게 무료로 제공하는 서비스의 일부 또는 전부를 회사의 경영상 필요에 의하여 변경,
											중단, 폐지할 수 있으며 이에 대하여 관련법에 특별한 규정이 없는 한 이용자에게 별도의 보상을 하지 않습니다.
										</li>
									</ol>

									<dt id="index7">제 7조 (서비스 이용시간)</dt>

									<ol className="step1">
										<li>서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</li>
										<li>
											펫닥에서 제공하는 모든 온라인 서비스는 24시간 이용 가능하며, 다만, 시스템 장애, 프로그램 오류 보수, 외부 요인 등 불가피한 경우에는 서비스 이용이 불가능 할 수 있습니다.
										</li>
										<li>
											회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 앱과 웹 공지사항에 공지한 바에 따릅니다.
										</li>
									</ol>

									<dt id="index8">제 8조 (정보의 제공 및 광고의 게재)</dt>

									<ol className="step1">
										<li>
											회사는 회원의 서비스 이용에 필요하다고 인정되는 정보를 공지사항 또는 서면(전자문서 또는 전자우편을 포함), 문자메시지(SMS), 전화, 애플리케이션 푸시(Push) 등의 방법으로 회원에게 제공할 수 있습니다.
											다만, 회원은 관련법에 따른 거래관련정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 위 정보의 제공수신을 거절할 수 있습니다.
										</li>
										<li>
											회사는 서비스의 운영과 관련하여 회사 홈페이지(서비스 초기화면), 전자우편, SMS, 앱 푸시(App Push) 등에 광고를 게재할 수 있습니다. 다만, 전자우편, SMS, 앱 푸시 등 전자적 전송매체의 경우
											정보통신망법에서 정한 바에 따라 원칙적으로 회사는 수신자의 명시적인 사전동의를 받아야 하고,
											수신자가 수신거부의사를 표시하거나 사전 동의를 철회한 경우에는 영리목적의 광고성 정보를 전송하지 않습니다. 광고가 게재된 전자우편을 수신한 회원은 수신거절을 회사에게 할 수 있습니다.
										</li>
									</ol>

									<dt id="index9">제 9조 (쿠폰의 사용)</dt>

									<ol className="step1">
										<li>
											회사는 서비스를 이용하는 회원에게 일정액 또는 일정비율을 할인 받을 수 있는, 서비스를 일정기간 무료 이용할 수 있는 쿠폰을 발급할 수 있습니다.
										</li>
										<li>
											회원은 쿠폰을 본인에 한해서만 사용할 수 있으며, 어떠한 경우에도 이를 타인에게 매매 또는 양도 할 수 없습니다.
										</li>
										<li>
											쿠폰은 경우에 따라 사용이 제한될 수 있으며, 유효기간이 지난 후에는 사용을 할 수 없습니다.
										</li>
										<li>
											회원에게 발행된 쿠폰은 서비스 1회 이용 시 중복 사용을 할 수 없습니다.
											<span className="underline">단, 쿠폰의 종류와 내용에 따라 중복사용 허용 등 사용범위를 달리 정한 경우에는 그러하지 않습니다.</span>
										</li>
										<li>
											회원을 탈퇴한 경우 쿠폰은 소멸됩니다.
										</li>
									</ol>

									<dt id="index10">제 10조 (서비스 이용과 지식 재산권)</dt>

									<ol className="step1">
										<li>
											회사가 서비스 제공과 관련하여 작성·편집한 저작물 등 자료 일체에 대한 지식재산권은 회사에 귀속됩니다. 이용자는 서비스 이용 과정에서 얻은 위 자료를
											(i) 회사의 명시적인 사전 승낙 없이 복제, 공중송신, 출판, 배포하거나,
											(ii) 이를 기초로 2차적저작물을 작성하거나,
											(iii) 이를 반복적 또는 기계적인 방법으로 복제하거나 제3자로 하여금 위 (i)~(iii)의 행위를 하게 하여서는 아니됩니다.
										</li>
										<li>
											회원이 서비스를 이용하여 서비스 상에 부호, 문자, 음성, 음향, 화상, 동영상 등의 정보형태의
											글, 사진, 동영상 및 각종 파일과 링크 등을 게시함 "게시물"의 저작권은 적법한 저작권자에게 귀속됩니다.
										</li>
										<li>
											회원 서비스를 이용하는 경우, 회사는 회원의 게시물을 회원 서비스 화면에 표시할 수 있습니다.
											이 경우, 회사는 저작권법 규정을 준수하며, 회원은 언제든지 고객센터 또는 앱과 웹 내 관리 기능을 통해 해당 게시물에 대해 삭제, 비공개 등의 조치를 취할 수 있습니다.
										</li>

										<li>
											회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리 침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시 조치 등을 취할 수 있습니다.
											기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시 조치 등을 취할 수 있습니다.
										</li>
										<li>
											회사는 바람직한 게시판 문화를 활성화하기 위하여 동의 없는 타인의 신상 공개시 특정부분을 삭제하거나 기호 등으로 수정하여 게시할 수 있습니다.
										</li>
										<li>
											게시물에 관련된 제반 권리와 책임은 작성자 개인에게 있습니다. 또 게시물을 통해 자발적으로 공개된 정보는 보호받기 어려우므로 정보 공개전에 심사숙고 하시기 바랍니다.
										</li>
										<li>가입 해지 시 고객께서 가입 시 작성 한 게시물은 유지됩니다.</li>
									</ol>

									<dt id="index11">제 11조 (게시물의 관리)</dt>

									<ol className="step1">
										<li>펫닥이 작성한 저작물에 대한 저작권 및 기타 지적 재산권은 펫닥에 귀속합니다.</li>
										<li>
											회원이 작성한 게시물에 대한 저작권 및 모든 책임은 이를 게시한 회원에게 있습니다.
											단**, "펫닥"은** "고객"이 게시하거나 등록하는 게시물의 내용이 다음 각 호에 해당한다고 판단되는 경우 해당 게시물을 사전 통지 없이 삭제 또는 임시 조치(블라인드)할 수 있습니다.
											<ol className="step2">
												<li style={{ listStyle:'none' }}>1) 다른 회원 또는 제3자를 비방하거나 명예를 손상시키는 내용인 경우</li>
												<li style={{ listStyle:'none' }}>2) 공공질서 및 미풍양속에 위반되는 내용일 경우</li>
												<li style={{ listStyle:'none' }}>3) 범죄적 행위에 결부된다고 인정되는 경우</li>
												<li style={{ listStyle:'none' }}>4) 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>
												<li style={{ listStyle:'none' }}>5) 회원이 사이트와 게시판에 음란물을 게재하거나 음란사이트를 링크하는 경우</li>
												<li style={{ listStyle:'none' }}>6) 회사로부터 사전 승인 받지 아니한 상업광고, 판촉 내용을 게시하는 경우</li>
												<li style={{ listStyle:'none' }}>7) 해당 상품과 관련 없는 내용인 경우</li>
												<li style={{ listStyle:'none' }}>8) 정당한 사유 없이 "펫닥" 또는 "서비스"의 영업을 방해하는 내용을 기재하는 경우</li>
												<li style={{ listStyle:'none' }}>9) 자신의 업소를 홍보할 목적으로 허위 또는 과장된 게시글을 게재하는 경우</li>
												<li style={{ listStyle:'none' }}>10) 의미 없는 문자 및 부호에 해당하는 경우</li>
												<li style={{ listStyle:'none' }}>11) 제3자 등으로부터 권리침해신고가 접수된 경우</li>
												<li style={{ listStyle:'none' }}>12) 관계법령에 위반된다고 판단되는 경우</li>
											</ol>
										</li>
										<li>
											회원이 서비스 내에 게시하는 게시물은 검색결과 내지 서비스 및 관련 프로모션 등에 노출될 수 있으며,
											해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우, 회사는 저작권법 규정을 준수하며,
											회원은 언제든지 고객센터 또는 서비스 내 관리 기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있습니다.
										</li>
										<li>
											회사는 제2항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우에는 전화,팩스,이메일 등을 통해 사전에 회원의 동의를 얻어야 합니다.
										</li>
									</ol>

									<dt id="index12">제 12조 (개인정보보호)</dt>
									<ol className="step1">
										<li>
											회사는 이용자의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.
										</li>
										<li>
											회사는 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다.
											다만, 관련 법령상 의무 이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.
										</li>
										<li>
											회사가 이용자의 개인 정보를 수집•이용하는 때에는 반드시 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.
										</li>
										<li>
											회사는 수집된 개인정보를 목적외의 용도로 이용할 수 없으며,
											새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용•제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.
											다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
										</li>
										<li>
											회사가 제2항과 제3항에 의해 이용자의 동의를 받아야만 하는 경우에는 개인정보보호책임자의 신원(소속, 성명 및 전화번호 기타 연락처),
											정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받는자, 제공목적 및 제공할 정보의 내용)등
											정보통신망이용촉진및정보보호등에관한법률 제22조 제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습 니다.
										</li>
										<li>
											회원은 언제든지 회사가 가지고 있는 자신의 개인 정보에 대해 열람 및 오류 정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다.
											이용자가 오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
										</li>
										<li>
											회사는 개인정보 보호를 위하여 이용자의 개인정보를 처리하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한
											이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
										</li>
										<li>
											회사 또는 그로부터 개인 정보를 제공받는 제3자는 개인정보의 수집 목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
										</li>
										<li>
											회사는 개인정보의 수집•이용•제공에 관한 동의란을 미리 선택한 것으로 설정해두지 않습니다.
											또한 개인정보의 수집•이용•제공에 관한 이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고, 필수
											수집항목이 아닌 개인정보의 수집•이용•제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.
										</li>
									</ol>


									<dt id="index13">제 13조 (회사의 의무)</dt>
									<ol className="step1">
										<li>
											회사는법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화, 용역을 제공하는데 최선을 다하여야 합니다.
										</li>
										<li>
											회사는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함) 보호를 위한 보안 시스템을 갖추어야 합니다.
										</li>
										<li>
											회사는 상품이나 용역에 대하여 "표시,광고의공정화에관한법률" 제3조 소정의 부당한 표시, 광고 행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
										</li>
										<li>회사는 이용자가 원하지 않는 영리 목적의 광고성 전자 우편을 발송하지 않습니다.</li>
									</ol>

									<dt id="index14">제 14조(회원의 ID 및 비밀번호에 대한 의무)</dt>
									<ol className="step1">
										<li>제 12조의 경우를 제외한 ID와 비밀번호에 관한 관리 책임은 회원에게 있습니다.</li>
										<li>회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</li>
										<li>
											회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에게 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.
										</li>
									</ol>

									<dt id="index15">제 15조 (손해배상 및 면책)</dt>
									<ol className="step1">
										<li>
											회원은 회사의 다음 각 호에 해당하는 행위로 손해를 입은 경우에 회사에 대해 손해배상을 청구할 수 있습니다. 이 경우 회원이 회사의 고의 또는 과실이 있음을 직접 입증하여야 합니다.
											<ol className="step2">
												<li style={{ listStyle:'none' }}>1) 법령에서 허용하는 경우를 제외하고 이용자 동의 없이 "고객"의 정보를 수집, 이용하는 행위</li>
												<li style={{ listStyle:'none' }}>2) 회원의 정보의 누출, 변조, 훼손 등의 행위</li>
											</ol>
										</li>
										<li>
											회원은 천재지변 등 불가항력적인 사유나 이용자의 고의 또는 과실로 인하여 발생한 때에는 손해를 배상하지 아니합니다.
										</li>
									</ol>

									<dt id="index16">제 16조 (분쟁의 조정)</dt>
									<ul style={{ paddingLeft:'10px' }}>
										<li style={{ listStyle:'none' }}>
											회사는 본 서비스 약관에 관련된 분쟁의 당사자 간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 전기통신기본법의 규정에 따라 방송통신위원회에 재정을 신청하거나
											정보통신망 이용촉진 및 정보 보호 등에 관한 법률의 규정에 의한 개인정보 분쟁 조정 위원회에 조정을 신청할 수 있습니다.
										</li>
									</ul>

									<dt id="index17">제 17조 (사업자 정보 및 정보 관리 책임자 지정)</dt>

									<ol className="step1">
										<li>"회사"는 "고객"의 정보를 관리, 보호하고, 거래 시 "고객"의 불만을 원활히 처리할 수 있는 정보관리책임자를 지정해 운영합니다.</li>
										<li>정보관리책임자는 서비스를 제공하는 부서의 부서장으로서 구체적인 사항은 본 약관의 부칙에 따릅 니다.</li>
										<li>
											회사의 상호, 주소, 전화번호 그 밖의 연락처는 다음과 같습니다.
											<ol className="step2">
												<li style={{ listStyle:'none' }}>1) 상호 : (주)펫닥</li>
												<li style={{ listStyle:'none' }}>2) 주소 : 서울특별시 서초구 강남대로79길 32</li>
												<li style={{ listStyle:'none' }}>3) 연락처 : 02-6949-3016</li>
												<li style={{ listStyle:'none' }}>4) 이메일 주소 : hello@petdoc.co.kr</li>
											</ol>
										</li>
										<li>
											정보관리 책임자는 다음과 같이 지정합니다.
											<ol className="step2">
												<li style={{ listStyle:'none' }}>1) 성명 : 오태환</li>
												<li style={{ listStyle:'none' }}>2) 소속 : 펫닥</li>
												<li style={{ listStyle:'none' }}>3) 전자우편 : cert@petdoc.co.kr</li>
											</ol>
										</li>
									</ol>

									이 약관은 2023년 11월 17일부터 적용됩니다.
								</dl>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Service;
